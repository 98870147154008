/* Font Size */
.fs-5 {
  font-size: 5px !important;
}
.fs-6 {
  font-size: 6px !important;
}
.fs-7 {
  font-size: 7px !important;
}
.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-23 {
  font-size: 23px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-25 {
  font-size: 25px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-27 {
  font-size: 27px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-29 {
  font-size: 29px !important;
}
.fs-30 {
  font-size: 30px !important;
}

.fs-35 {
  font-size: 35px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-45 {
  font-size: 45px !important;
}
.fs-50 {
  font-size: 50px !important;
}

/* Font weight */
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.fw-1000 {
  font-weight: 1000 !important;
}

/* Width */

.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.w-100 {
  width: 100% !important;
}

/* Margin */

.m-5 {
  margin: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.ms-5 {
  margin-left: 5px !important;
}
.me-5 {
  margin-right: 5px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.m-10 {
  margin: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ms-10 {
  margin-left: 10px !important;
}
.me-10 {
  margin-right: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-15 {
  margin: 15px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.ms-15 {
  margin-left: 15px !important;
}
.me-15 {
  margin-right: 15px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-20 {
  margin: 20px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.ms-20 {
  margin-left: 20px !important;
}
.me-20 {
  margin-right: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-25 {
  margin: 25px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.ms-25 {
  margin-left: 25px !important;
}
.me-25 {
  margin-right: 25px !important;
}

.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.m-30 {
  margin: 30px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.ms-30 {
  margin-left: 30px !important;
}
.me-30 {
  margin-right: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-35 {
  margin: 35px !important;
  }
  .mt-35 {
  margin-top: 35px !important;
  }
  .mb-35 {
  margin-bottom: 35px !important;
  }
  .ms-35 {
  margin-left: 35px !important;
  }
  .me-35 {
  margin-right: 35px !important;
  }
  
  .mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
  }
  
  .my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
  }
  
  .m-40 {
  margin: 40px !important;
  }
  .mt-40 {
  margin-top: 40px !important;
  }
  .mb-40 {
  margin-bottom: 40px !important;
  }
  .ms-40 {
  margin-left: 40px !important;
  }
  .me-40 {
  margin-right: 40px !important;
  }
  
  .mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
  }
  
  .my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  }
  
  .m-45 {
  margin: 45px !important;
  }
  .mt-45 {
  margin-top: 45px !important;
  }
  .mb-45 {
  margin-bottom: 45px !important;
  }
  .ms-45 {
  margin-left: 45px !important;
  }
  .me-45 {
  margin-right: 45px !important;
  }
  
  .mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
  }
  
  .my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
  }
  
  .m-50 {
  margin: 50px !important;
  }
  .mt-50 {
  margin-top: 50px !important;
  }
  .mb-50 {
  margin-bottom: 50px !important;
  }
  .ms-50 {
  margin-left: 50px !important;
  }
  .me-50 {
  margin-right: 50px !important;
  }
  
  .mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
  }
  
  .my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  }

  .m-55 {
    margin: 55px !important;
    }
    .mt-55 {
    margin-top: 55px !important;
    }
    .mb-55 {
    margin-bottom: 55px !important;
    }
    .ms-55 {
    margin-left: 55px !important;
    }
    .me-55 {
    margin-right: 55px !important;
    }
    
    .mx-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
    }
    
    .my-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
    }
    
    .m-60 {
    margin: 60px !important;
    }
    .mt-60 {
    margin-top: 60px !important;
    }
    .mb-60 {
    margin-bottom: 60px !important;
    }
    .ms-60 {
    margin-left: 60px !important;
    }
    .me-60 {
    margin-right: 60px !important;
    }
    
    .mx-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
    }
    
    .my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    }
    
    .m-65 {
    margin: 65px !important;
    }
    .mt-65 {
    margin-top: 65px !important;
    }
    .mb-65 {
    margin-bottom: 65px !important;
    }
    .ms-65 {
    margin-left: 65px !important;
    }
    .me-65 {
    margin-right: 65px !important;
    }
    
    .mx-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
    }
    
    .my-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
    }
    
    .m-70 {
    margin: 70px !important;
    }
    .mt-70 {
    margin-top: 70px !important;
    }
    .mb-70 {
    margin-bottom: 70px !important;
    }
    .ms-70 {
    margin-left: 70px !important;
    }
    .me-70 {
    margin-right: 70px !important;
    }
    
    .mx-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
    }
    
    .my-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
    }

    .text-center{
      text-align: center !important;
    }