.filter-card {
    // background-color: red;
    border: 0.4px solid #464646;
    display: flex;
    justify-content: flex-start;
    position: relative;
    // width: 8em;
    border-radius: 4px;
    padding: 0.1em 0.2em;
    margin-right: 1em;
    margin-top: 1em;
    cursor: pointer;
}

.close-icon {
    position: absolute;
    top: 0.2em;
    right: 0.2em;
}

.fc-icon-wrapper {
    display: flex;
    align-items: center;
    padding-right: 1.1em;
}

.fc-img-box {
    width: 1em;
    height: 1em;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-icon {
    width: 100%;
    object-fit: cover;
}

.fc-filter-active {
    background-color: #C8FFA4;
}

.no-data-container {
    margin: 4em auto;
}

.wrap {
    flex-wrap: wrap;
}