// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

.hero-text {
  font-family: "Poppins", sans-serif;
  font-size: 8rem;
  font-weight: 500;
  /* line-height: 1em; */
  color: #0a2615;
}

.hero-desc {
  margin-top: 40px;
  color: #606963;
  font-size: 20px;
}

.hero-left {
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
}

.hero-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-btn-1 {
  padding: 10px 30px !important;
  background-color: #76ba1b !important;
  box-shadow: none !important;
  border-radius: 20px 0 0 20px !important;
}

.hero-btn-2 {
  padding: 10px 30px !important;
  background-color: #50514d !important;
  box-shadow: none !important;
  border-radius: 0 20px 20px 0 !important;
}

.text-home {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  border: 2px solid black;
}

/* Category */
.category-heading {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 50px;
}

.category-card {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  border-radius: 30px;
}

.category-card img {
  width: 300px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  transition: all 0.2s ease;
}

.category-card img:hover {
  width: 300px;
  filter: none;
  transform: translate(0, -5px);
}

.category-car-title {
  text-align: center;
  color: #65922a;
  font-weight: 500;
}

/* Product */

.product-container {
  background-color: #e8fdd8;
  border-radius: 20px;
}

/* hero secondary */
.hero-container {
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #181818;
  border-radius: 8px;
  padding: 2em 0;
  /* padding-top: 3em;
  margin-top: 3em; */
  /* padding-bottom:5em; */
}

.hero-child-1 {
  transform: translate3d(-10px, 0px, 30px);
}

.hero-label {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 70px;
  /* display: flex; */
  /* align-items: center; */
  text-align: center;
  text-transform: capitalize;

  /* #464646 */

  color: #464646;
}

.hero-child-2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  text-transform: capitalize;
}

/* hero head */
.herohead-container {
  display: grid;
  /* justify-content: space-between;
  padding-right: 3em; */
  grid-template-columns: 1fr 1fr;
  /* gap: 70px; */
  /* background-color: yellow; */
  /* justify-content: space-between; */
  
}

.Hero-Heading {
  margin-top: -0.7em;
}


.hero-child-3 {
  transform: translate3d(116px, -2px, 0px);
}

/*  Brand */
.brand-container {
  /* background-color: blue; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.brand-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  margin-bottom: 0;
}

/* footer */
.footer {
  /* background-color: red; */
  background-image: url("../trees.svg");
  background-position: center bottom;
  background-repeat: no-repeat;
  /* width: 21em; */
  height: 21em;
  margin-top: 5em;
}

.hero-carousel {
  position: relative;
  width: 90%;
  height: 350px;
  margin: 0 auto;
}

.hero-carousel-Image {
  width: 400px;
}

.Hero-Search {
  margin: 2em 0;
  display: grid;
  // grid-template-columns: 4fr 1fr 1fr;
  // gap: 1em;
}

/* Products */

.Hero-Product {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  // margin-left: 6rem;
}
.togglebtn{
  display: none;
}
@media (max-width: 576px) {

  html {
    overflow-x: hidden;
  }

  .hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.277027px solid #181818;
    border-radius: 2.21622px;
    /* width: 328px; */
    height: 90.59px;
  }

  .hero-child-1 {
    width: 50%;
    height: 40%;
    transform: translate3d(-24px, -48px, 0px);
    content-visibility: visible;
    /* transform: translate3d(-10px, 0px, 30px); */
  }

  .hero-child-2 {
    font-family: "Roboto";
    font-style: normal;
    width: 100%;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #464646;
  }

  .hero-child-3 {
    /* height: 50px; */
    /* height: -50px; */
    transform: translate3d(0px, 10px, 316px);
    padding-bottom: 1.2em;
  }

  .leafImage {
    width: 4em;
    /* transform: scaleX(-1); */
  }

  .herohead-container {
    display: grid;
    grid-template-rows: 1fr;
  }

  .herohead-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .hero-text {
    font-family: "Poppins", sans-serif;
    font-size: 5rem;
    font-weight: 400;
    /* line-height: 0.7em; */
    width: max-content;
    /* margin: 4em 0; */

    /* color: #0a2615; */
  }

  .Hero-Heading {
    margin-top: -0.7em;
  }

  .hero-carousel {
    position: relative;
    width: 100%;
    height: 220px;
    margin: 0 auto;
  }

  .hero-carousel-Image {
    width: 220px;
  }

  .Hero-Search {
    margin: 2em 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
  }

  /* Top Products */

  .Hero-Product {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;
  }
  .Hero-product1{
    display: flex;
    justify-content: normal;
    align-items: center;
    flex-direction: column;
    gap:6px;

  }

  .togglebtn{
    display: block;
  }

  /* find by brand  */

  .brand-container {
    /* background-color: blue; */
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1.2em;
  }
  .hero-carousel {
    position: relative;
    width: 75%;
    height: 350px;
    margin: 0 auto;
  }

}
@media (max-width: 850px) {
  .hero-child-3 {
    /* height: 50px; */
    /* height: -50px; */
    transform: translate3d(0px, 10px, 316px);
    // padding-bottom: 1.2em;
  }
  .herohead-container {
   
    grid-template-rows: 1fr;
    display: grid;
    grid-template-columns: 1fr;
  }
  .hero-carousel {
    position: relative;
    width: 80%;
    height: 350px;
    margin: 0 auto;
  }
  .category-car-title{
    // position: absolute;
    margin-bottom: -16rem;
    margin-left: -11rem;

  }
  
}
// @media(max-width:344px){
//   .hero-carousel {
//     position: relative;
//     width: 100%;
//     height: 350px;
//     // margin: 0 auto;
//   }
//   .hero-child-3 {
//     /* height: 50px; */
//     /* height: -50px; */
//     transform: translate3d(0px, 10px, 316px);
//     // padding-bottom: 1.2em;
//   }
//   .herohead-container {
   
//     grid-template-rows: 1fr;
//     display: grid;
//     grid-template-columns: 1fr;
//   }
//   }
  .herohead-container {
   
    // grid-template-rows: 1fr;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 1rem;
  }

  @media (max-width: 930px){
    .hero-child-3 {
      /* height: 50px; */
      /* height: -50px; */
      transform: translate3d(0px, 10px, 316px);
      padding-bottom: 1.2em;
     
  .herohead-container {
    display: grid;
    grid-template-rows: 1fr;
  }

  .herohead-container {
    display: grid;
    grid-template-columns: 1fr;
  }
    }
  }
@media only screen and (min-width: 912px) and (max-width: 1366px) {
  .hero-child-3 {
    /* height: 50px; */
    /* height: -50px; */
    transform: translate3d(0px, 10px, 316px);
    padding-bottom: 1.2em;
  }
  .herohead-container {
   
    // grid-template-rows: 1fr;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 1rem;
  }
  .hero-carousel {
    position: relative;
    width: 90%;
    height: 350px;
    margin: 0 auto;
  }
}
@media  (max-width: 854px){

.hero-child-3 {
  /* height: 50px; */
  /* height: -50px; */
  transform: translate3d(0px, 10px, 316px);
  // padding-bottom: 1.2em;
}
.herohead-container {
 
  grid-template-rows: 1fr;
  display: grid;
  grid-template-columns: 1fr;
}
.hero-carousel {
  position: relative;
  width: 80%;
  height: 350px;
  margin: 0 auto;
}
}