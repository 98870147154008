/* @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=Manrope:wght@200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"); */
/* @import "./css/navbar.css"; */
/* @import "./css/home.css"; */
/* @import "./css/product-card.css"; */
/* @import "./css/Brand.css"; */

body {
  font-family: "Manrope", sans-serif;
  background-color: #ebf3ff !important;
}

code {
  font-family: "Manrope", sans-serif;
}

p {
  margin: 0;
}
