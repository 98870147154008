.blur-bg {
    background: rgba(255, 255, 255, 0.14);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-cont {
    background-color: #fff;
    // width: 8em;
    height: 6em;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}