/* @import url("https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,600,700,800, 800i, 900&display=swap"); */


.container-prod1 {
  position: relative;
  transition: 5s;
}

.container-prod1 .card1 {
  position: relative;
  width: 590px;
  height: 400px;
  /* background: #232323; */
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #c8ffa4;
  transition: 5s;
  
}

/* 
.container-prod .card:hover {
  position: relative;
  width: 590px;
  height: 404px;
  background: #232323;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #c8ffa4;
} */

.container-prod1 .card1:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #1bbfe9; */
  background: #c8ffa4;
  clip-path: circle(420px at 60% -30%);
  transition: 0.5s ease-in-out;
}

.container-prod .card:hover:before {
  clip-path: circle(400px at 40% -20%);
}

.container-prod1 .card1:after {
  /* content: "ddd"; */
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 12em;
  font-weight: 800;
  font-style: italic;
  color: rgba(13, 79, 30, 0.093);
  
}

.container-prod1 .card1 .imgBx1 {
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}

.container-prod1 .card1 .imgBx1 {  
  top: 0%;
  transform: translateY(-25%);
  /* bug  hover */
}

.container-prod1 .card1 .imgBx1 img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  transition: all 0.2s ease;
}

.container-prod1 .card1 .imgBx1 img {
  width: 220px;
  /* bug  hover */
}

.container-prod1 .card1 .contentBx1 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 90;
}

.container-prod1 .card1 .contentBx1 {
  /* height: 210px; */
  height: 210px;
}

.container-prod1 .card1 .contentBx1 h2 {
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 20px;
  color: #1c1c1c;
}

.container-prod1 .card1 .card1-close1 a{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  /* Other close icon styles */
}
.container-prod1 .card1 .contentBx1 .size1,
.container-prod1 .card1 .contentBx1 .color1 {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.container-prod1 .card1 .contentBx1 .size1 {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container-prod1 .card1 .contentBx1 .color1 {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.container-prod1 .card1 .contentBx1 .size1 h3,
.container-prod1 .card1 .contentBx1 .color1 h3 {
  color: white;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.container-prod1 .card1 .contentBx1 .size1 span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  transition: 0.5s;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
}

.container-prod1 .card1 .contentBx1 .size1 span:hover {
  /* other bug */
  background: #b90000;
}

.container-prod1 .card1 .contentBx1 .color1 span {
  width: 20px;
  height: 20px;
  background: #ff0;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.container-prod1 .card1 .contentBx1 .color1 span:nth-child(2) {
  background: #1bbfe9;
}

.container-prod1 .card1 .contentBx1 .color1 span:nth-child(3) {
  background: #1b2fe9;
}

.container-prod1 .card1 .contentBx1 .color1 span:nth-child(4) {
  background: #080481;
}

.container-prod1 .card1 .contentBx1 a{
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  color: #464646;
  background-color: #C8FFA4;
  opacity: 1;
  transform: translateY(50px);
  transition: 0.5s;
  margin-top: 10px;
  box-shadow: 0px 4px 2px rgba(75, 75, 75, 0.2);
}

.container-prod1 .card1 .contentBx1 a {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.7s;
}

.container-prod1 .more-card1 {
  position: relative;
  width: 300px;
  height: 400px;
  /* background: #232323; */
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #c8ffa4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Card-footer1{
  display: flex;
  justify-content: center;
  align-items: center;
}
.Card-footer1 img {
  margin: 0 0.3em;
}

.Card-footer1 a {
  margin: 0 0.9em;
}

  .crossbtn{
    height:38px; 
    width:38px;
    position: absolute;
    left: 34rem;
    top: -0.1em;
    z-index: 2000;
   
  }
  
.modal-overlay {
  position: fixed;
  top: 2em;
  left: 2em;
  width: 590px;
  height: 400px;
  background: rgba(248, 244, 244, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  z-index: 3000;
}

.modal-content {
  position: relative;
  width:590px;
  height: 400px;
  /* background: white; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 7px;
  cursor: pointer;
  font-size: 24px;
  color: black;
  z-index: 3000;

}
.modal-content img{
width: 590px;
height: 400px;
border-radius: 0.5em;
object-fit: contain;
overflow: hidden;

}
@media (max-width: 850px) {
  /* .container-prod1{
    width: 350px;
    height: 500px;
  } */

  .container-prod1 .card1 {
    position: relative;
    width: 330px;
    height: 400px;
    /* background: #232323; */
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #c8ffa4;
    transition: 5s;
    
  }
  .modal-overlay {
    position: fixed;
    top: 2em;
    left: 2em;
    width: 330px;
    height: 400px;
  }
  
.modal-content {
  position: relative;
  width:330px;
  height: 400px;
  /* background: white; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.modal-content img {
   
    width:330px;
    height: 400px;
    /* background: white; */
    border-radius: 0.5em;
object-fit: contain;
/* z-index: 3000; */
/* overflow: hidden; */
   
  }
  .crossbtn{
    height:38px; 
    width:38px;
    position: absolute;
    left: 18rem;
    top: -0.1em;
    /* margin-right:-2rem;
    margin-top:-1.8rem; */
  }
  .Card-footer1 a {
    position: absolute;
    top: 12rem;
  }
  .imgBx1{
    margin-top: -0.8rem;
  }
  .contentBx1{
    margin-bottom: 2rem;
  }
}

