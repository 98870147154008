// @import url("https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,600,700,800, 800i, 900&display=swap");


.container-prod {
  position: relative;
}

.container-prod .card {
  position: relative;
  width: 300px;
  height: 420px;
  /* background: #232323; */
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #c8ffa4;
  // border: 1px solid black;
  // transition: 5s ease;
}

/* 
.container-prod .card:hover {
  position: relative;
  width: 590px;
  height: 404px;
  background: #232323;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #c8ffa4;
} */

.container-prod .card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #1bbfe9; */
  background: #c8ffa4;
  /* clip-path: circle(150px at 80% 20%); */
  // transition: 0.5s ease-in-out;
}

.container-prod .card:hover:before {
  clip-path: circle(400px at 40% -20%);
}

.container-prod .card:after {
  /* content: "ddd"; */
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 12em;
  font-weight: 800;
  font-style: italic;
  color: rgba(13, 79, 30, 0.093);
}

.container-prod .card .imgBx {
  position: absolute;
  top: 0;
  // transform: translateY(-60%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  // transition: 0.5s;
}

.container-prod .card:hover .imgBx {
position: absolute;
  top: 0;
  height: 50%;
  transform: translateY(0%);
  /* bug  */
}

.container-prod .card .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  // transform: translate(-50%, -50%);
  width: 200px;
  // transition: all 0.2s ease;

}


// .container-prod .card:hover .imgBx img {
//   width: 120px;  
//   /* bug  */
// }

.container-prod .card .contentBx {
  position: absolute;
  bottom: 1.3rem;
  width: 100%;
  height: 100px;
  text-align: center;
  // transition: 1s;
  z-index: 90;
}

.container-prod .card:hover .contentBx {
  height: 180px;
  // top:50%
}

.container-prod .card .contentBx h2 {
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 15px;
  color: #1c1c1c;
  top: 0%
}
// .container-prod .card .contentBx:hover h2 {
//   position: relative;
//   font-weight: 600;
//   letter-spacing: 1px;
//   font-size: 15px;
//   color: #1c1c1c;
//   top:0;
// }




.container-prod .card .contentBx .size,
.container-prod .card .contentBx .color {
  display: flex;
  justify-content: center;
  align-items: center;
  // transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.container-prod .card:hover .contentBx .size {
  opacity: 1;
  visibility: visible;
  // transition-delay: 0.5s;
}

.container-prod .card:hover .contentBx .color {
  opacity: 1;
  visibility: visible;
  // transition-delay: 0.6s;
}

.container-prod .card .contentBx .size h3,
.container-prod .card .contentBx .color h3 {
  color: white;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.container-prod .card .contentBx .size span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  // transition: 0.5s;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
}

.container-prod .card .contentBx .size span:hover {
  /* other bug */
  background: #b90000;
}

.container-prod .card .contentBx .color span {
  width: 20px;
  height: 20px;
  background: #ff0;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.container-prod .card .contentBx .color span:nth-child(2) {
  background: #1bbfe9;
}

.container-prod .card .contentBx .color span:nth-child(3) {
  background: #1b2fe9;
}

.container-prod .card .contentBx .color span:nth-child(4) {
  background: #080481;
}

.container-prod .card .contentBx a {
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  color: #464646;
  background-color: #C8FFA4;
  opacity: 0;
  transform: translateY(50px);
  // transition: 0.5s;
  margin-top: 10px;
  box-shadow: 0px 4px 2px rgba(75, 75, 75, 0.2);
}

.container-prod .card:hover .contentBx a {
  opacity: 1;
  transform: translateY(0px);
  // transition-delay: 0.7s;
}

.container-prod .more-card {
  position: relative;
  width: 300px;
  height: 400px;
  /* background: #232323; */
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #c8ffa4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:2em ;
  margin-left: 2em;
}

.Card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-info-btn {
  background: #C8FFA4;
  border: none;
  box-shadow: 0px 4px 2px rgba(75, 75, 75, 0.2);
  border-radius: 5.08911px;
  margin-top: 2rem;
 
 }
 
.buy-btn1{
  
  margin-top: 0.6rem;
  // border: 2px solid red;
}
.buy-btn{
//  margin-top: -2rem;
 background: #C8FFA4;
 border: none;
 box-shadow: 0px 4px 2px rgba(75, 75, 75, 0.2);
 border-radius: 5.08911px;

}
.remove-btn{
  background: #C8FFA4;
  border: none;
  box-shadow: 0px 4px 2px rgba(75, 75, 75, 0.2);
  border-radius: 50%;
}
.add-btn{
  background: #C8FFA4;
  border: none;
  box-shadow: 0px 4px 2px rgba(75, 75, 75, 0.2);
  border-radius: 50%;
}
@media (max-width: 576px) {
  .container-prod2 .card2 {
    position: relative;
    width: 110px;
    height: 122.06px;
    /* background: #232323; */
    // background: #ffffff;
    border-radius:10px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid black;
    // transition: 1s ease;
  }
  .container-prod2 {
    position: relative;
  }
  

  // /* 
  // .container-prod .card:hover {
  //   position: relative;
  //   width: 590px;
  //   height: 404px;
  //   background: #232323;
  //   background: #ffffff;
  //   border-radius: 20px;
  //   overflow: hidden;
  //   cursor: pointer;
  //   border: 2px solid #c8ffa4;
  // } */
  
  .container-prod2 .card2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: #1bbfe9; */
    background: #c8ffa4;
    /* clip-path: circle(150px at 80% 20%); */
    // transition: 0.5s ease-in-out;
  }
  
  .container-prod2 .card2:hover:before {
    clip-path: circle(400px at 40% -20%);
  }
  
  .container-prod2 .card2:after {
    /* content: "ddd"; */
    position: absolute;
    top: 30%;
    left: -20%;
    font-size: 12em;
    font-weight: 800;
    font-style: italic;
    color: rgba(13, 79, 30, 0.093);
  }
  
  .container-prod2 .card2 .imgBx {
    position: absolute;
    top: 0;
    // transform: translateY(-60%);
    z-index: 1000;
    width: 100%;
    height: 100%;
    // transition: 0.5s;
  }
  
  .container-prod2 .card2:hover .imgBx {
  position: absolute;
    top: 0;
    height: 50%;
    transform: translateY(0%);
    /* bug  */
  }
  
  .container-prod2 .card2 .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    // transform: translate(-50%, -50%);
    width: 200px;
    // transition: all 0.2s ease;
  
  }
  
  
  // .container-prod .card:hover .imgBx img {
  //   width: 120px;  
  //   /* bug  */
  // }
  
  .container-prod2 .card2 .contentBx {
    position: absolute;
    bottom: 1.3rem;
    width: 100%;
    height: 100px;
    text-align: center;
    // transition: 1s;
    z-index: 90;
  }
  
  .container-prod2 .card2:hover .contentBx {
    height: 180px;
    // top:50%
  }
  
  .container-prod2 .card2 .contentBx h2 {
    position: relative;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 15px;
    color: #1c1c1c;
    top: 0%
  }
  // .container-prod .card .contentBx:hover h2 {
  //   position: relative;
  //   font-weight: 600;
  //   letter-spacing: 1px;
  //   font-size: 15px;
  //   color: #1c1c1c;
  //   top:0;
  // }
  
  
  
  
  .container-prod2 .card2 .contentBx .size,
  .container-prod2 .card2 .contentBx .color {
    display: flex;
    justify-content: center;
    align-items: center;
    // transition: 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  
  .container-prod2 .card2:hover .contentBx .size {
    opacity: 1;
    visibility: visible;
    // transition-delay: 0.5s;
  }
  
  .container-prod2 .card2:hover .contentBx .color {
    opacity: 1;
    visibility: visible;
    // transition-delay: 0.6s;
  }
  
  .container-prod2 .card2 .contentBx .size h3,
  .container-prod2 .card2 .contentBx .color h3 {
    color: white;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 10px;
  }
  
  .container-prod2 .card2 .contentBx .size span {
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    font-size: 14px;
    display: inline-block;
    color: #111;
    background: #fff;
    margin: 0 5px;
    // transition: 0.5s;
    color: #111;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .container-prod2 .card2 .contentBx .size span:hover {
    /* other bug */
    background: #b90000;
  }
  
  .container-prod2 .card2 .contentBx .color span {
    width: 20px;
    height: 20px;
    background: #ff0;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .container-prod2 .card2 .contentBx .color span:nth-child(2) {
    background: #1bbfe9;
  }
  
  .container-prod2 .card2.contentBx .color span:nth-child(3) {
    background: #1b2fe9;
  }
  
  .container-prod2 .card2 .contentBx .color span:nth-child(4) {
    background: #080481;
  }
  
  .container-prod2 .card2 .contentBx a {
    display: inline-block;
    padding: 4px 16px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    color: #464646;
    background-color: #C8FFA4;
    opacity: 0;
    transform: translateY(50px);
    // transition: 0.5s;
    margin-top: 10px;
    box-shadow: 0px 4px 2px rgba(75, 75, 75, 0.2);
  }
  
  .container-prod2 .card2:hover .contentBx a {
    opacity: 1;
    transform: translateY(0px);
    // transition-delay: 0.7s;
  }
  
  .container-prod2 .more-card {
    position: relative;
    width: 300px;
    height: 400px;
    /* background: #232323; */
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #c8ffa4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:2em ;
    margin-left: 2em;
  }
}