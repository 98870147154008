.clear-btn:hover{
    background-color: red;
    color: white;
    
}
.clear-btn{
    border:1px solid red;
    border-radius:20px;
    
    font-weight: bolder;
}