.navbar {
  display: flex;
  position: fixed;
  height: 100px;
  width: 100%;
  overflow: hidden;
  top: 0;
  align-items: center;
  background: #c8ffa4;
  z-index: 1000;
  position: relative;

}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid blue; */
}

.logo {
  width: 180px;
  height: 50px;
}

.nav-learn-btn {
  color: #6ab04c !important;
  border: 2px solid #6ab04c !important;
  border-radius: 30px !important;
  margin-left: 20px !important;
  padding-right: 25px !important;
  padding-left: 25px !important;
}

#menu {
  // margin: 0.5rem;
  text-align: center;
//  border: 2px solid red;
}

#menu a {
  color: #333;
  text-decoration: none;
  font-weight: 600;
  /* margin-left: 0.6em; */
  transition: color 0.3s;
  text-transform: uppercase;
  padding: 1.5em;

}

#menu a:hover {
  color: #6ab04c;
}

.header {
  width: 100%;
  height: 600px;
  background: url("https://source.unsplash.com/Oo33n4t-qzc/1600x900") no-repeat 50% 50%;
  background-size: cover;
  margin-top: 80px;
}

#btn {
  display: none;
  position: absolute;
  right: 20px;
  top: 25%;
}

#btn:hover>span {
  background-color: #6ab04c;
}

#btn span {
  display: block;
  margin: 6px;
  width: 40px;
  height: 3px;
  color: #464646;
  background: #333;
}

.navbar-toggle {
  display: none;

}

@media (max-width: 600px) {
  .navbar {
    display: visible;
    /* overflow: visible; */
    visibility: visible;
    opacity: 1;
  }

  .navbar .menu {
    position: fixed;
    top: 0px;
    left: -5px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    background-color: #c8ffa4;
    transition: 5s;

  }

  .navbar a {
    font-size: 1.3rem;
  }

  /* .menu a {
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 10px;
    margin: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: 5s ease;
  } */
  #btn {
    display: block;
    cursor: pointer;
  }

  .show {
    max-height: 500px;
  }

  .navbar-toggle {
    display: block;
    position: absolute;
    top: 2rem;
    right: 1.3rem;
  }

  .close-menu {
    display: none;
  }

  a {
    display: flex;
    align-items: center;
    /* justify-content: start; */
  }

  .Menu-item {
    display: flex;
    /* width: 40%; */
    flex-direction: column;
    /* border: 2px solid red; */
    justify-content: start;
    /* background-color: #6ab04c; */

  }

}
@media (max-width: 850px) {

  .navbar .menu {
    position:fixed;
    top: 0px;
    // left: -5px;
    height: 80%;
    width: 56%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:start;
    gap: 4rem;
    // background-color: #c8ffa4;
    transition: 5s;
    left: 44%;
    padding-top: 4em; 
    background: rgba(200, 255, 164,0.8); 
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); 
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .navbar a {
    font-size: 1.3rem;
  }
  #btn {
    display: block;
    cursor: pointer;
  }

  .logo {
    width: 140px;
    height: 30px;
    position: relative;
    bottom: 0.4em;
  }
  .ev{
    position: relative;
    top: 1.5em;
    left: -6.7em;
  }
  .show {
    max-height: 500px;
  }

  .navbar-toggle {
    display: block;
    position: absolute;
    // top: 1.5rem;
    right: 2rem;
    width: 40px;
    height: 40px;
  }

  .close-menu {
    display: none;
  }

  a {
    display: flex;
    align-items: center;
    /* justify-content: start; */
  }
  .Menu-item {
    display: flex;
    /* width: 40%; */
    flex-direction: column;
    /* border: 2px solid red; */
    justify-content: start;
    /* background-color: #6ab04c; */

  }
}
#basic-button {
  /* display: block; */
  color: #464646;
  /* z-index: 1; */
  /* position: relative; */
}

#basic-menu {
  position: absolute;
  z-index: 1000;
}

.linkTag {
  text-decoration: none;
  list-style: none;
  color: #464646;
  padding: 0;
  margin: 0;
}

.linkTag:hover {
  text-decoration: none;
  color: #464646;
}

.homeImage {
  padding-left: 1em;
}

.HomeAnchor {
  padding-left: 0;
}